import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'

import * as nav from '@data/navdata'

const SubnavGifts = ({
    activeSubnav,
    handleToggleSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role="navigation"
        >
            <div className="mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]">
                <div
                    className="subnav-nav-back cursor-pointer p-3 relative left-2 z-10 w-[32px]"
                    onClick={handleToggleSubnav}
                    data-nav="gifts"
                >
                    <AngleLeft width="w-[12px]" />
                </div>
                <h2 className="text-center text-2xl flex-1 relative -left-[24px]">
                    Gifts + Bundles
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                >
                    <span className="close-x menu-close relative top-[5px] !font-bold">
                        <CloseX width="w-[16px]" />
                    </span>
                </div>
            </div>
            <ul className="nostyle">
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.baby_gifts}>
                        Shop All Gifts
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.gift_cards}>
                        Gift Cards
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.embroidery_shop}>
                        Embroidery Shop
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.welcoming_baby}>
                        Welcoming Baby
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.toys}>
                        Toys + Books
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.baby_blankets}>
                        Blankets
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.loungewear}>
                        Signature Robes
                    </a>
                </li>
            </ul>
            <div
                className="subsubnav subnav-gifts-bundles bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="gift_bundles"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="gift_bundles"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text pl-6 text-lg pb-3">
                        Gift Boxes
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.gift_bundles === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-gift-bundles ${
                        activeSubSubnav && activeSubSubnav.gift_bundles
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="gift_bundles"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.gift_boxes}>
                                Shop All Gift Boxes
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.cuddle_box}>
                                Hospital Cuddle Box™
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_first_year}>
                                Baby's First Year Box
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.modern_layette}>
                                The Modern Layette Box
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.snuggle_box}>
                                Snuggle Cuddle Box
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavGifts
