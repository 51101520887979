import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import * as nav from '@data/navdata'

const SubnavNewArrivals = ({
    activeSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleToggleSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role="navigation"
        >
            <div className="mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]">
                <div
                    className="subnav-nav-back cursor-pointer p-3 relative left-2 z-10 w-[32px]"
                    onClick={handleToggleSubnav}
                    data-nav="new_arrivals"
                >
                    <AngleLeft width="w-[12px]" />
                </div>
                <h2 className="text-center text-2xl flex-1 relative -left-[24px]">
                    New + Featured
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                >
                    <span className="close-x menu-close relative top-[5px] !font-bold">
                        <CloseX width="w-[16px]" />
                    </span>
                </div>
            </div>
            <div
                className='menu-section-header flex items-center w-full py-4 border-t border-oat'
                data-subnav='new_arrivals'
                onClick={handleToggleSubSubnav}
            >
                <span className='menu-section-text pl-6 text-lg'>
                    Shop New Arrivals
                </span>{' '}
                <span className='ml-auto mr-5'>
                    {activeSubSubnav &&
                    activeSubSubnav.new_arrivals === true ? (
                        <Minus width='w-[16px]' />
                    ) : (
                        <Plus width='w-[18px]' />
                    )}
                </span>
            </div>
            <div
                className={`subsubnav subsubnav-new-arrivals bg-[#FFFAF5] ${
                    activeSubSubnav && activeSubSubnav.new_arrivals === true
                        ? 'block'
                        : 'hidden'
                }`}
                data-subnav="new_arrivals"
            >
                <ul className="nostyle">
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.new_arrivals}>
                            Shop All New Arrivals
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.easter_shop}>
                            The Easter Capsule
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.spring_collection}>
                            The Spring Collection
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.spring_sports}>
                            The Spring Sports Capsule
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.hello_baby}>
                            The HelloBaby Collection
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.toy_story}>
                            Disney Pixar Toy Story
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.disney_princesses}>
                            Disney Princesses
                        </a>
                    </li>
                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.moana}>
                            Disney's Moana
                        </a>
                    </li> */}
                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.disney_holiday}>
                            The Disney Holiday Collection
                        </a>
                    </li> */}


                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.embroidery_shop}>
                            Embroidery Shop
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.cozy_collection}>
                            The Cozy Collection
                        </a>
                    </li> */}
                </ul>
            </div>
            <div
                className="subsubnav subnav-featured-shops bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="featured_shops"
            >
                <div
                    className={`menu-section-header flex items-center w-full ${activeSubSubnav &&
                        activeSubSubnav.featured_shops === true ? 'pb-3' : ''}`}
                    data-subnav="featured_shops"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text pl-6 text-lg">
                        Featured Shops
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.featured_shops === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-featured-shops ${
                        activeSubSubnav && activeSubSubnav.featured_shops
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="featured_shops"
                >
                <ul className="nostyle">
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.all_products}>
                            Shop All Featured Collections
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.all_pajamas}>
                            The Pajama Shop
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.all_dresses}>
                            The Dress Shop
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.all_swim}>
                            The Swim Shop
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.all_tees}>
                            The Tee Shop
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_rompers}>
                            The One-Piece Shop
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.embroidery_shop}>
                            The Embroidery Shop
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.cozy_collection}>
                            The Cozy Collection
                        </a>
                    </li>

                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.moana}>
                            Disney's Moana
                        </a>
                    </li> */}
                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.disney_holiday}>
                            The Disney Holiday Collection
                        </a>
                    </li> */}


                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.embroidery_shop}>
                            Embroidery Shop
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.cozy_collection}>
                            The Cozy Collection
                        </a>
                    </li> */}
                </ul>
            </div>
            </div>
            <div
                className="subsubnav subnav-baby-sizes bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="more_new_arrivals"
            >
                <div
                    className="menu-section-header flex items-center w-full pb-3"
                    data-subnav="more_new_arrivals"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text pl-6 text-lg">
                        Shop By Age
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.more_new_arrivals === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-more-new-arrivals ${
                        activeSubSubnav && activeSubSubnav.more_new_arrivals
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="more_new_arrivals"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_new_arrivals}>
                                Baby New Arrivals
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a
                                className="block"
                                href={nav.toddler_new_arrivals}
                            >
                                Toddler New Arrivals
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.kids_new_arrivals}>
                                Kids New Arrivals
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a
                                className="block"
                                href={nav.maternity_new_arrivals}
                            >
                                Maternity New Arrivals
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavNewArrivals
